<template>
  <div :class="{ esign: true, enter: enter}" ref="esignBox">
    <div class="scroll" >
      <div class="esign-bg"></div>
      <div class="esign-cav">
        <vue-esign
          ref="esign"
          :width="height"
          :height="width"
          :isCrop="isCrop"
          :lineWidth="lineWidth"
          :lineColor="lineColor"
          :bgColor.sync="bgColor"
        />
      </div>
      <!-- <div class="esign-box" ontouchmove="event.preventDefault()">
        
      </div> -->
      <div class="esign-title" ontouchmove="event.preventDefault()">
        <img @click="goBack" class="esign-title-img" src="@/assets/close.png" alt="" />
        <div class="esign-title-text">和诚签</div>
        <div class="esign-title-reset">
          <img class="img" src="@/assets/reset.png" alt=""  @click="handleReset" />
          <img class="img" src="@/assets/signok.png" alt="" @click="handleGenerate" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vueEsign from "vue-esign";
import { savePersonSeal } from '@/util/api'
import { oss } from '@/util/tool'
import { mapState, mapMutations } from 'vuex'

export default {
  components: { vueEsign },
  data() {
    return {
      lineWidth: 5,
      lineColor: "#000000",
      bgColor: "",
      resultImg: "",
      isCrop: false,
      width: document.documentElement.offsetHeight,
      height: document.documentElement.clientWidth - 50,
      enter: false
    };
  },
  computed: {
    ...mapState(['linkCodeInfo', 'signatureDetails'])
  },
  methods: {
    ...mapMutations(['signOver']),
    handleReset() {
      this.$refs.esign.reset();
    },
    handleGenerate() {
      this.$refs.esign.generate().then((res) => {
          console.log("res", res);
          const img = new Image()
          // 这里的思维就是，把图片映射到一个画板上，然后对图片进行重新绘制，所以这里要建一个canvas对象来充当我们的画板
          const canvas = document.createElement('canvas')
          // base64转换image对象
          img.src = this.$refs.esign.resultImg
          // 这里记得一定要在base64转换成图片对象后再进行其它操作，笔者在这里踩了个坑，onload方法就是图片加载再进行其它操作，如果图片是文件路径方式跨域调用效果更加明显
          img.onload = () => {
            // img的高度和宽度不能在img元素隐藏后获取，否则会出错
            const height = img.height
            const width = img.width
            // 旋转角度以弧度值为参数
            const ctx = canvas.getContext('2d')
            canvas.width = height
            canvas.height = width
            ctx.rotate(-90 * Math.PI /180)
            ctx.drawImage(img, 0, 0, -width, height)
            canvas.toDataURL("image/png")
            const path = this.dataURLtoFile(canvas.toDataURL("image/png"))
            oss(path).then(({size, object, host}) => {
              const params = {
                files: [{ fileDisplayName: 'signImage', fileExt: '.png', fileSize: size, fileUrl: `${host}${object}` }],
                processId: `${this.linkCodeInfo.processId}`,
                height: document.documentElement.clientWidth,
                width: document.documentElement.offsetHeight
              }

              savePersonSeal(params).then(() => {
                this.handleReset()
                this.signOver(Math.random() *10)
                this.goBack()
              })
            })
          }
        })
        .catch((err) => {
          alert(err.msg); // 画布没有签字时会执行这里 'Not Signned'
        });
    },
    dataURLtoFile(dataurl) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    goBack() {
      if(sessionStorage.getItem('sourceType')&&sessionStorage.getItem('sourceType') == 'miniWx'){
        this.$router.replace({ path: '/sign',query:{signatureId:this.signatureDetails.id}})
      }else {
        this.$router.back(1)
      }
    },
    random_string(len) {
      len = len || 32
      var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
      var maxPos = chars.length
      var pwd = ''
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos))
      }
      return pwd
    }
  },

  mounted() {
    this.enter = true
  }
};
</script>

<style lang='less'>
@keyframes fold-left-in {
    0% {
      transform:rotate(0deg);
    }
    50% {
      transform:rotate(-90deg);
    }
    100% {
      transform:rotate(0deg);
    }
  }
.enter {
    // animation: fold-left-in 0.1s ease;
}
.esign {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  // width: 375px;
  height: 100%;
  /* transform: rotate(90deg); */
  // position: relative;
  overflow: scroll;
  background: #f5f6f9;
  // border: 1px solid red;
  box-sizing: border-box;
  // background: red;
  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../assets/draw-bg.png");
    background-size: auto 50%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    z-index: 10;
  }
  &-cav {
    z-index: 11;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    // width: calc(100% - 50px);
    height: 100%;
    overflow: hidden;
  }
  &-box {
    width: 30px;
    height: 100%;
    // transform-origin: center;
    // transform: rotate(90deg);
    position: fixed;
    // left: 276.5px;
    // left: 14px;
    top: -40px;
    right: 10px;
    z-index: 14;
    // background: red;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    &-reset,
    &-ok {
      width: 26px;
      height: 26px;
      margin-bottom: 30px;
      transform-origin: center;
      transform: rotate(90deg);
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  &-title {
    width: 50px;
    height: 100%;
    background: #0067e1;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 12;
    display: flex;
    align-items: center;
    justify-content: center;
    &-img {
      width: 22px;
      height: 22px;
      display: inline-block;
      position: fixed;
      right: 14px;
      top: 20px;
    }
    &-text {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 300px;
      height: 26px;
      margin-left: -13px;
      margin-top: -100px;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ffffff;
      // text-align: center;

      transform-origin: left top;
      transform: rotate(90deg) translateY(-100%);
    }
    &-reset {
      width: 80px;
      height: 30px;
      position: fixed;
      top: calc(100% - 100px);
      display: flex;
      transform: rotate(90deg);
      display: flex;
      align-items: center;
      .img {
        width: 22px;
        height: 22px;
        margin-left: 20px;
      }
    }
  }
}
.scroll {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 101%;
  // background: green;
  z-index: 12;
  overflow: scroll;
}
</style>